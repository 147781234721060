<template>
    <div class="form-horizontal">
        <p>Login with {{ title }} requires that your user account has been set up. If you are not able to login, please contact your system administrator.</p>

        <ErrorComponent :errors="errors" :capsLockOn="capsLockOn" :key="state.updated" :exclude="['username', 'password']"/> 

        <button type="button" :name="name" @click="authenticateExternal()" :disabled="isAuthenticated" class="btn btn-o365-login login-alternative" :title="`Sign in with ${title}`">{{ (isAuthenticated ? 'Logged in with '  : 'Log in with ') + title}}</button>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed, inject, onMounted } from 'vue';
    import { getReturnUrl } from 'o365.modules.Login.shared.js';
    import ErrorComponent from 'o365.vue.components.Login.Errors.vue';
    
    const isBusy = inject('isBusy') as Function;
    
    const props = defineProps({ 
        state: { type: Object, required: true } ,
        name:String,
        title:String,
        capsLockOn: { type: Boolean, default: false },
        errors: { type: Object, required: false, default: {}}
    });
    
    const isAuthenticated = computed(() => props.state.isAuthenticated);

    const authenticated = inject('authenticated') as Function;
    const returnUrl = getReturnUrl();

    onMounted(() => {
        isBusy(false);
    });

    async function authenticateExternal() {
        try {
            // errors.value = {};
            if(window.parent.location.pathname.replace('/nt/', '/') === props.state.loginPath){
                location.replace(`/api/login/auth/${props.name}?Login=true&ReturnUrl=${encodeURIComponent(returnUrl)}`);
                return;
            }

            var popupWindow = window.open(`/api/login/auth/${props.name}?Login=true`, "login");
            if (popupWindow && popupWindow.focus)
                popupWindow.focus();
            var pollTimer = window.setInterval(function () {
                if(!popupWindow) { 
                    console.log('clear Interval because window is not found');
                    window.clearInterval(pollTimer);
                    return; 
                }
                if (popupWindow.closed) {
                    console.log('clear Interval because window closed');
                    window.clearInterval(pollTimer);
                }
                try {
                    if (popupWindow.location.host === window.location.host && popupWindow.location.pathname !== '/login') {
                        console.log('clear Interval because host match');
                        window.clearInterval(pollTimer);
                        popupWindow.close();
                        authenticated(props.name);
                    }
                } catch (e) {
                    console.log(e);   
                }             
            }, 200);           
        } catch(e) {
            console.log(e);
        }
    }

</script>